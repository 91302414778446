body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-list-item-meta-description {
  color: #999999
}

.ant-list-item-meta-title {
  color: black;
  cursor: pointer;
}

.ant-list-item {
  padding: 8px
} 
.ant-modal-footer {
  border-top: 0px;
  text-align: center
}
.ant-modal-header {
  border-bottom: 0px
}
.ant-card-bordered {
  border: none;
}
.ant-modal-title{
  display: flex;
  justify-content: center
}
.ant-select-show-search{
  display: flex;
  justify-content: center
}
.ant-select-auto-complete.ant-select .ant-input{
  height: 50px;
  width: 350px
}
.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 1em;
  font-family: 'Source Code Pro', monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
}
.ant-list-item-meta-title{
  display: flex;
  /* justify-content: center; */
}
.ant-radio-checked .ant-radio-inner {
  border-color: #333333 !important;
  box-shadow: none !important;
}
.ant-radio-input:hover{
  border-color: #333333 !important;
  box-shadow: none !important;
}
#feedback-list> .ant-list-item-meta-content > .ant-list-item-meta-title{
  /* background-color: red !important;
  color: #00f !important; */
  display: flex;
  justify-content: center;
}
.ant-radio-checked::after{
  border: 1px solid  #333333 !important;
  box-shadow: none !important;
}
.ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner{
  border-color: #333333 !important;
  box-shadow: none !important;
}
.ant-radio-inner::after {
  background-color: #333333;
  box-shadow: none !important;
}
.preferance-btn{
  background-color: #333333 !important;
  border-color: #333333 !important;
}
.paymentLabel{
  margin-bottom: .5rem;
  color: #6b7c93;
  font-weight: bold;
  letter-spacing: 0.025em;
  display: inline;
}
.paymentNameLabel{
  display: block;
  margin: 10px 0 20px 0;
  width: 500px !important;
  padding: 10px 14px;
  font-size: 1em;
  font-family: 'Source Code Pro', monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
}
.formContainer{
  position: relative;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0px;
  margin-bottom: 100px;
  background-color: #ffffff;
  width: 95vw;
  max-width: 100%;
}
#payButton{
  /* width: 63%; */
  background: #333333 !important; 
  color: #FFF !important;
  box-shadow: none !important;
  border: none !important;
}
#cardSpace{
  margin: 10px
}
#tableContainer{
  margin-top: 20px;
}
.col-xs-2{
  background:#00f;
  color:#FFF;
}
.col-half-offset{
  margin-left:4.166666667%
}
.colorbar{
  display: inline-block;
  margin: -2px;
  width: 13%;
  height: 12px;
  border:1px solid #333333;
}
.slick-slider.slick-vertical.slick-initialized{
  /* width:auto; */
  width: 18%;
  /* max-width: max-content; */
}
/* .slick-next:hover, .slick-next:focus {
  outline: none !important;
  background: black !important;
}
.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus {
  outline: none;
  background: black;
  color: blue;
} */
/* .card-modal.slick-prev:before, .slick-next:before {
  color:black !important;
} */
/* #stack-content-view.slick-prev:before, .slick-next:before {
  color:#FFF !important;
} */
.slick-slide slick-active slick-current{
  display: flex;
  justify-content: center
}
/* .slick-prev, .slick-next{
  background: black
} */
#slider-view .slick-prev:before, .slick-next:before {
  color:black;
}
#slider-view .slick-prev:before{
   color: black;
}

#slider-view .slick-next:before{
  color: black;
}

#snow-slider-fullView .slick-prev:before, .slick-next:before {
  color:#FFF;
}
#snow-slider-fullView .slick-prev:before{
   color: #FFF;
}

#snow-slider-fullView .slick-next:before{
  color: #FFF;
}
#snow-slider-fullView .slick-slider.slick-vertical.slick-initialized{
  color:#fff;
  margin-bottom: 0 !important;
  width:75%;
}
#home-slider .slick-prev:before, .slick-next:before {
 color:#FFF;
}
#location-carousel-view{
  display: flex;
  justify-content: center;
}
#home-slider .slick-prev:before{
  color: #FFF;
}
#home-slider{
  display: flex;
    /* justify-content: center; */
    font-size: 15px;
    font-weight: 600;
    width: 250px;
}
#home-slider .slick-list{
  height: 70px;
  text-align: center
}
#stack-content-view .slick-slider.slick-initialized {
  width: 115px;
  height: 45px;
  margin-bottom: 0;
  text-align: center;
  padding-top: 10px;
  /* padding-right: 10px; */
}
#stack-content-view .slick-list{
  height: 50px;
}
#home-slider .slick-next:before{
  color: #FFF;
}
/* .card-modal .slick-prev:before, .slick-next:before{
  color:black !important;
} */
/* #slider-view .slick-prev:before{
  color:black !important;
}
#slider-view .slick-prev:before, .slick-next:before{
  color: black !important;
}
#modal-card-container .slick-prev:before, .slick-next:before{
  color:black !important;
} */
/* .pickerText .slick-prev:before, .slick-next:before {
  color:black !important;
} */
/* .pickerText .slick-prev:before, .slick-next:before{
  color:black !important;
} */
.ant-col-8{
  width:100% !important;
  display: flex !important;
}
@media(min-width:670px) and (max-width:992px){
  .slick-slider.slick-vertical.slick-initialized{
    width: 46% !important;
  }
}
@media(min-width:415px) and (max-width:669px){
  .slick-slider.slick-vertical.slick-initialized{
    width: 55% !important;
  }
}
@media(max-width:414px){
  .slick-slider.slick-vertical.slick-initialized{
    width: 70% !important;
  }
}
.ant-list-item-meta-content .ant-list-item-meta-title::selection{
  background: red !important;
  color: white !important;
}
.header-view .mainheaderContent{
  background: #333333 !important;
}
.ant-layout-sider {
  /* position: relative; */
  /* min-width: 0; */
  background: #333333 !important;
  /* -webkit-transition: all 0.2s; */
  /* transition: all 0.2s; */
}
.card-view >div{
  padding: 0px !important;
}
.mapView >div{
  width: auto !important;
  height: calc(100vh - 64px) !important; 
}
#sidebarHeader{
  background: #333333 !important;
}
.ant-layout-sider-zero-width-trigger{
  background: #333333 !important;
}
ul.ant-menu.ant-menu-dark.ant-menu-root.ant-menu-inline {
  background: #333333 !important;
}
.snow-value-modal{
  /* border: 1px solid #333333; */
  width: calc(400px - 198px);
    /* border: 1px solid; */
}
.content-scale-view{
  /* width: calc(400px - 200px); */
}
.scale-container-view{
  width: 400px;
  overflow-y: visible;
  display: flex;
  justify-content: center;
}
.scale-modal-head{
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* .react-switch-handle{ */
  /* background: #333333 !important; */
/* } */
#add-card{
  overflow-y: visible !important;
  overflow-x: hidden !important;
  max-width: 100%;
  min-height: 95vh;
}
#add-card::-webkit-scrollbar {
  width: 2px;
  height: 4px;
}
.nav{
 position: fixed;
      top: 70px;}
/* Track */
#add-card::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
#add-card::-webkit-scrollbar-thumb {
  background: #333333; 
  border-radius: 10px;
}

/* Handle on hover */
#add-card::-webkit-scrollbar-thumb:hover {
  background: #333333; 
}
#submit-button-view{
  background: #333333 !important; 
  color: #FFF !important;
  box-shadow: none !important;
  border: none !important;
}
#submit-button-view:hover{
  background: #333333 !important; 
  color: #FFF !important;
  box-shadow: none !important;
  border: none !important;
}
#common-button-view{
  background: #333333 !important; 
  color: #FFF !important;
  box-shadow: none !important;
  border: none !important;
}
/* logo view */
#logo-view{
  max-width:100%;
  width:320px;
  height: 100%;
}
@media(max-width:500px){
#card-form-view{
  max-width: 65% !important;
  overflow-x: hidden;
}
}
.react-switch{
  margin-top: 5%;
  margin-right: 3px;
}
.slick-track{
  height: 0px !important;
}
.mapboxgl-ctrl-top-right{
  top:50px !important;
}
@media(max-width:800px){
  .feedback-details-view{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .feedback-content-view{
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
  }
  .toggle-view{
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
  }
}
@media(max-width:378px){
.mapboxgl-ctrl-top-right .mapboxgl-ctrl{
  margin-top: 55px !important;
}
.scale-value-view{
  width: 430px !important;
}
/* .nav{
  margin-top: 55px !important;
} */
}
@media(min-width:379px) and (max-width:600px){
  .mapboxgl-ctrl-top-right .mapboxgl-ctrl{
    margin-top: 25px !important;
  }
  /* .nav{
    margin-top: 25px !important;
  } */
  }
  @media(min-width:601px) and (max-width:800px){
    /* .nav{
      margin-top: 35px !important;
    } */
    .mapboxgl-ctrl-top-right .mapboxgl-ctrl{
      margin-top: 25px !important;
    }
  }
  @media(max-width:639px){
    #logo-view{
      width: 199px;
      /* height: 55px; */
    }
  }
  @media(max-width:370px){
    .Demo__search-bar-container {
      width: 65% !important;
      max-width: 500px !important;
      /* float: right !important; */
	  position: fixed;
      top: 60px;
  }
  .nav {
    position: fixed;
      top: 70px;
}
  }
  @media(min-width:371px) and (max-width:500px){
    .Demo__search-bar-container {
      width: 65% !important;
      max-width: 500px !important;
      /* float: right !important; */
      position: fixed;
      top: 60px;
  }
  .nav {
 position: fixed;
      top: 70px;}
  }
  @media(min-width:561px) and (max-width:600px){
  .Demo__search-bar-container {
    width: 65% !important;
    max-width: 500px !important;
    /* float: right !important; */
    position: fixed;
      top: 60px;
}
.nav {
 position: fixed;
      top: 70px;}

  }
  @media(min-width:601px) and (max-width:700px){
    .Demo__search-bar-container {
      width: 65% !important;
      max-width: 500px !important;
      /* float: right !important; */
     position: fixed;
      top: 60px;
  }
  .nav {
 position: fixed;
      top: 70px;  }
  }

  @media(min-width:701px) and (max-width:800px){
    .Demo__search-bar-container {
      width: 65% !important;
      max-width: 500px !important;
      /* float: right !important; */
      position: fixed;
      top: 60px;
      /* position: relative; */
      /* bottom: 8% !important; */
  }
  .nav {
 position: fixed;
      top: 70px;  }
  }
  @media(min-width:801px) and (max-width:1000px){
    .Demo__search-bar-container {
      width: 60vw !important;
      max-width: 500px !important;
      /* float: right !important; */
      position: fixed;
      top: 60px;
  }
  .nav {
 position: fixed;
      top: 70px;  }
  }
  @media (min-width:350px) and (max-width:767px){
    .scale-container-view{
    }
  }
  @media(max-width:349px){
    .scale-container-view{
      }
  }
  @media(min-width:1001px){
    .Demo__search-bar-container {
      width: 60vw !important;
      /* float: right !important; */
      position: fixed;
      top: 60px;
  }
  
   .Demo__search-input-container{
	 margin-left: 15vw !important;
 }
 
  .nav {
 position: fixed;
      top: 70px;  }
  }

  /* .logo-dimensions {
    max-width: 320px;
  } */
  #add-location-modal > div{
    width:600px;
  }
  #add-location-modal{
    display: flex;
    justify-content: center;
    height: calc(100vh - 152px) !important;
  }
  @media(min-width:550px) and (max-width:560px){
    .mobile-view-header{
      margin-top:11%;
    }
    .Demo__search-bar-container {
      width: 65% !important;
      max-width: 500px !important;
      /* float: right !important; */
      position: fixed;
      top: 60px;
  }
  .nav {
 position: fixed;
      top: 70px;  }
  }
  @media(min-width:540px) and (max-width:559px){
    .mobile-view-header{
      margin-top:11%;
    }
    .Demo__search-bar-container {
      width: 65% !important;
      max-width: 500px !important;
      /* float: right !important; */
      position: fixed;
      top: 60px;
  }
  .nav {
 position: fixed;
      top: 70px;  }
  }
  @media(min-width:530px) and (max-width:549px){
    .mobile-view-header{
      margin-top:11%;
    }
    .Demo__search-bar-container {
      width: 65% !important;
      max-width: 500px !important;
      /* float: right !important; */
      position: fixed;
      top: 60px;
  }
  .nav {
 position: fixed;
      top: 70px;  }
  }
  @media(min-width:417px) and (max-width:529px){
    .mobile-view-header{
      margin-top:14%;
    }
  
  }
  @media(min-width:400px) and (max-width:416px){
    .mobile-view-header{
      margin-top:15%;
    }
  
  }
  @media(min-width:400px) and (max-width:529px){
  .Demo__search-bar-container {
    width: 65% !important;
    max-width: 500px !important;
    /* float: right !important; */
    position: fixed;
      top: 60px;
}
.nav {
 position: fixed;
      top: 70px;}
  }
  @media(min-width:345px) and (max-width:399px){
    .Demo__search-bar-container {
      width: 65% !important;
      max-width: 500px !important;
      /* float: right !important; */
      position: fixed;
      top: 60px;
  }
  .nav {
 position: fixed;
      top: 70px;  }
    }
    @media(min-width:345px) and (max-width:399px){
      .mobile-view-header{
        margin-top:17%;
      }
    
    }
    @media (max-width:344px){
      .Demo__search-bar-container {
        width: 65% !important;
        max-width: 500px !important;
        /* float: right !important; */
        position: fixed;
      top: 60px;
    }
    .nav {
 position: fixed;
      top: 70px;    }
      }
      @media(min-width:315px) and (max-width:344px){
        .mobile-view-header{
          margin-top:19%;
        }
      
      }
      @media(max-width:1200px){
        /* #modal-card{
          height: calc(100vh - 37vh) !important;
          
        } */
        #add-location-modal{
          height: auto !important;
        }
      }
      @media(max-width:360px){
      .hourly-image{
        height: 220px !important;
      }
      div#layer-view{
        right:-9px !important;
      }
    }
    @media(min-width:361px) and (max-width:767px){
      div#layer-view{
        right:5px !important;
      }
      .hourly-image{
        height: 300px !important;
      }
    }
    .header-modal-view .ant-modal-content{
      background-color: black;
    }
    .header-modal-view .ant-modal-header{
      background-color: black;
    }
    .header-modal-view .ant-modal-title{
      color:#fff !important;
    }
    .header-modal-view .ant-modal-close-x{
      color:darkgrey
    }
    .header-modal-view .ant-modal-close-x i{
      font-size: 20px;
    }
    @media(max-width:480px){
     .containerStyle .mdl-card.mdl-shadow--2dp.firebaseui-container.firebaseui-id-page-sign-in {
        width: 100% !important;
    }
    .containerStyle .ant-card.ant-card-bordered{
      width: 100% !important;
    }
    .mdl-card.mdl-shadow--2dp.firebaseui-container.firebaseui-id-page-password-sign-in{
      width:100% !important;
    }
    .firebaseui-container{
      width: 100% !important;
    }
    }
    @media(max-width:767px){
     
    #prefrence-view .preference-view{
        overflow: auto !important;
        height: calc(100vh - 85px) !important;
      }
      /* .aqua-view{
        margin-left: 65.6667px !important; 
      } */
      #column-view{
        overflow: visible !important;
      }
      .Demo__search-input-container input{
        height: 45px;
		 top: 30px !important;
		 width: 60vw !important;
      }
      .Demo__search-input-container i.anticon.anticon-search{
        left: 10px !important;
        top: 34px !important;
      }
      #search-topo-icon {
        /* position: relative; */
        /* top: 63px !important; */
        /* left: -20px !important; */
        /* background: red; */
        padding: 0px;
        width: 45px !important;
        height: 45px !important;
      }
      div#help-slope-view{
        width: 25% !important;
      }
      #add-location-view{
        width: 183px !important;
      }
      #location-carousel-view #snow-slider-fullView{
        width:100% !important;
		margin-left: 0vw !important;
		margin-right: 0vw !important;
      }
      #location-carousel-view #snow-slider-view{
        width:70% !important;
      }
      #location-carousel-view button.slick-arrow.slick-prev{
        left: -40px !important;
     }
     #location-carousel-view button.slick-arrow.slick-next {
       right: -40px !important;
     }
     #find-location-view{
       width:50% !important;
     }
     .mapView >div{
      width: auto !important;
      height: calc(100vh - 64px)!important;
    }
    }
    @media(min-width:768px){
      #location-carousel-view #snow-slider-fullView{
        margin-left: 30vw !important;
		margin-right: 30vw !important;
      }
    }
	@media(max-width:768px){
      #location-carousel-view #snow-slider-fullView{
        margin-left: 0vw !important;
		margin-right: 0vw !important;
      }
    }
    #location-list{
      padding-left: 5px;
    }
    #location-carousel-view button.slick-arrow.slick-prev{
       display: block;
       position: absolute;
       top: 30px;
       left: -30px;
    }
    #location-carousel-view button.slick-arrow.slick-next {
      position: absolute;
      top: 30px;
      right: -30px;
    }
    
 /* @media(max-width:767px) {
 
} */
/* @media screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0){ */
  /* @media screen and (max-width:767px) { */

/* } */
/* } */

@media(min-width:768px) and (max-width:1300px){
  #location-content-view{
    right: 9% !important;
  }
  #stack-content-view{
    right: 9% !important;  
  }
}
#search-topo-icon{
  position: relative;
  top: 23px;
  left: 20px;
  background: white;
  padding: 0px;
  border-radius: 5px;
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* #search-topo-icon img{
  width: 90%;
  height: 90%;
} */
/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: gray; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: gray; 
}
 #gradient-color-view1{
  background: linear-gradient(to right, #fff 0%, #d9d9ff 10%, #aaaaff 20%, #ababff 30%, #7f7fff 40%, #7e7eff 50%, #6d6dff 60%, #3838ff 70%, #0d0dff 80%, #001eff 100%);
  width: 10px;
  height: 10px;
}
#gradient-color-view2{
  background: linear-gradient(to right, #001eff  0%, #0146b6 10%, #00649a 20%, #008c70 30%, #009865 40%, #08ed1f 50%, #08e11e 60%, #0bfb03 70%, #06ee0e 80%, #0dff00 100%);
  width: 80px;
  height: 10px;
}
#gradient-color-view3{
  background: linear-gradient(to right, #0dff00  0%, #37ff00 10%, #5eff00 20%, #62ff00 30%, #80ff00 40%, #08ed1f 50%, #aaff00 60%, #d4ff00 70%, #ffff00 80%, #ffff00 100%);
  width: 80px;
  height: 10px;
}
#gradient-color-view4{
  background: linear-gradient(to right, #ffff00  0%, #ffd500 10%, #ffb700 20%, #ff9500 30%, #ff8c00 40%, #ff6200 50%, #ff1100 60%, #ff0d00 70%, #f60509 80%, #e30e20 100%);
  width: 80px;
  height: 10px;
}
#gradient-color-view5{
  background: linear-gradient(to right, #e30e20   0%, #e90d1c 10%, #e91224 20%, #e30e20 30%, #d31935 40%, #c91e3d 50%, #c42245 60%, #c91d3d 70%, #bd264e 80%, #ba2a55 100%);
  width: 80px;
  height: 10px;
}
#last-gradient-view{
  display: flex;
  justify-content: space-between;
  width: 80px;
}
@media (min-width:370px) and (max-width:767px){
  #gradient-color-view1, #gradient-color-view2, #gradient-color-view3, #gradient-color-view4, #gradient-color-view5,#last-gradient-view{
    width: 40px !important;
  }
  #gradient-container{
    padding: 15px !important;
    width:122% !important;
    position: relative !important;
    right: 19% !important;
  }
}
@media(min-width:330px) and (max-width:369px){
  #gradient-color-view1, #gradient-color-view2, #gradient-color-view3, #gradient-color-view4, #gradient-color-view5,#last-gradient-view{
    width: 35px !important;
  }
  #gradient-container{
    padding: 15px !important;
    width:122% !important;
    position: relative !important;
    right: 19% !important;
    top: 20px !important;
  }
}
@media (max-width:329px){
  #gradient-color-view1, #gradient-color-view2, #gradient-color-view3, #gradient-color-view4, #gradient-color-view5,#last-gradient-view{
    width: 35px !important;
  }
  #gradient-container{
    padding: 15px !important;
    width:122% !important;
    position: relative !important;
    right: 19% !important;
    top: 20px !important;
  }
}
.header-modal-view .ant-modal-content{
  min-height: 520px;
}
@media(max-width:767px){
  .header-modal-view .ant-modal-content{
    height: auto !important; 
  }
}
#gradient-container{
  position: relative;
}
/* .Demo__search-bar-container{ */
 /* display: flex !important;
 align-items: flex-start; */
/* } */
#sidebarHeaderContainer .ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected{
  background-color: #000 !important;
}
@media(max-width:600px){
  .Demo__search-bar-container{
    bottom:0 !important;
  }
  #no-consistent-content{
    height: calc(100vh - 390px) !important;
  }
}
#no-consistent-content{
  height: calc(100vh - 450px);
  display: flex;
  align-items: center;
}
@media(min-width:768px) and (max-width:991px){
  #no-consistent-content{
    height: calc(100vh - 705px) !important;
  }
  #content-view-icon{
    width: 15% !important;
  }
}
@media(min-width:992px) and (max-width:1024px){
  #no-consistent-content{
    height: calc(100vh - 78vh) !important;
  }
  #content-view-icon{
    width: 15% !important;
  }
}

@media(min-height:1001px){
  #latestLegendColor{
    height: 40px;
  }
}
@media(max-height:1000px) and (min-height:801px){
  #latestLegendColor{
    height: 30px;
  }
}
@media(max-height:800px) and (min-height:701px){
  #latestLegendColor{
    height: 25px;
  }
}
@media(max-height:700px) and (min-height:601px){
  #latestLegendColor{
    height: 20px;
  }
}
@media(max-height:600px) and (min-height:501px){
  #latestLegendColor{
    height: 15px;
  }
}
@media(max-height:500px){
  #latestLegendColor{
    height: 10px;
  }
}



@media(min-width:1001px){
	#linear-container-view{
    width: calc(75*4px);
  }
  #linear-container-modal{
  width: 4px;
  }
}
@media(max-width:1000px) and (min-width:801px){
 #linear-container-view{
    width: calc(75*4px);
  }
  #linear-container-modal{
  width: 4px;
  }
}
@media(max-width:800px) and (min-width:701px){
#linear-container-view{
    width: calc(75*3px);
  }
  #linear-container-modal{
  width: 3px;
  }
}
@media(max-width:700px) and (min-width:601px){
#linear-container-view{
    width: calc(75*3px);
  }
  #linear-container-modal{
  width: 3px;
  }
}
@media(max-width:600px) and (min-width:501px){
  #linear-container-view{
    width: calc(75*2px);
  }
  #linear-container-modal{
  width: 2px;
  }
}
@media(max-width:500px){
#linear-container-view{
    width: calc(75*2px);
  }
  #linear-container-modal{
  width: 2px;
  }
    #stack-content-view{
	right: 10px !important;
	width: fit-content !important;
  }
}
@media all and (display-mode: standalone) {
  #location-content-view{
    bottom: 9% !important;
  }
  #stack-content-view{
    top: calc(100% - 200px) !important;  
	height: fit-content !important;
  }
}
 .Demo__search-input-container{
	 margin-left: 20vw;
 }
 
 @media all {
  #stack-content-view{
	height: fit-content !important;
  }
  #map-banner{
	  position: fixed;
	  left: 0;
	  width: 100vw !important; 
	  height: 20px !important; 
	  top: 64px !important;
	  justify-content: center; 
	  align-items: center;
	  text-align: center;
	  background-color: rgba(0,0,0,.75);
  }
  
}

#top_controls{
  /* width: 30%;
  max-width: 500px;
  float: right;
  margin-top: 5%; */
height: 80px !important;
  width: 45%;
  /* max-width: 500px; */
  margin-top: 10px;
  display: flex;
  align-items: flex-start;
  margin-left: auto;
  margin-right: auto;
}