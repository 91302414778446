.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.mdl-button--raised.mdl-button--colored {
  background:#00AEEF!important;
  color: #fff;
}
.mdl-button--primary.mdl-button--primary {
  color: #00AEEF!important;
}
.mdl-button--primary.mdl-button--primary:hover {
  background-color: #00AEEF!important;
  color: #fff !important;
}
.App-link {
  color: #61dafb;
}
.firebaseui-container{
  max-width:700px !important;
  width:450px;
}
.ant-card-body{
  padding:10px !important;
}
.forgotSubmit{
  display: flex;
  justify-content: center;
}
.containerStyle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
}
.mainheaderContent{
  display: flex !important;
  justify-content: center !important;
}
#sidebarHeader{
  padding:0px !important;
  width: max-content !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Demo__search-input-container {
  position: relative;
  width:60vw;
}

.Demo__search-input,
.Demo__search-input:focus,
.Demo__search-input:active {
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08);
  border: honeydew;
  display: block;
  width: 100%;
  padding: 16px;
  font-size: 16px;
  border-radius: 2px;
  outline: none;
}

.container {
  position: relative;
  text-align: center;
  color: white;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.centeredSub {
	position: relative;
  display: block;
}

.ant-carousel .slick-slide {
  text-align: center;
  height: 160px;
  line-height: 160px;
  background: black;
  overflow: hidden;
}

.ant-carousel .slick-slide h3 {
  color: #fff;
}
.slick-vertical .slick-slide {
  display: flex;
  justify-content: center;
  height: 25px;
  border: 1px solid transparent;
}
.ant-btn-lg{
  height: 55px;
  width: -webkit-fill-available!important
}
.ant-layout-sider-zero-width-trigger {
  top: 9px !important;
  right: 0px !important;
}
.span.ant-input-search.ant-input-search-enter-button.ant-input-group-wrapper {
  width:100% !important;
}
@media(max-width:350px){
.locationImage{
  margin-right:-60px !important;
}
}
@media(min-width:400px) and (max-width:500px){
  .containerStyle{
    width:100% !important;
  }
}
/* @media(min-width:300px) and (max-width:399px){
  .containerStyle{
    width:125% !important;
  }
} */
.ant-btn-primary{
  background: #282c34 !important; 
  color: #fff !important;
  border: none !important;
}
@media not all and (min-resolution:.001dpcm) {
  #modal-card{
    height: calc(100vh - 84px) !important;
  }
  #modal-card-view{
    height: calc(100vh - 84px) !important;
  }
  #modal-card-list{
    height: calc(100vh - 84px) !important;
  }
}
@media(min-width:300px) and (max-width:678px){
.card-modal{
  /* width: max-content; */
  width: calc(100vw - 0px);
}
.mapView > div{
  width: calc(100vw - 0px) !important
}
}
@media (max-width:560px){
  #mobile-logo-view {
    width: 100%;
    z-index: 1;
    position: absolute;
  }
  #sidebar-icon-view{
    position: relative;
    z-index: 9999;
  }
  #modal-card{
    height: calc(100vh - 22px) !important;
    margin-top: 64px !important;
    overflow: hidden !important;
   
  }
  #modal-card-view{
    height: calc(100vh - 2px) !important;
    margin-top: 64px !important;
    overflow: hidden !important;
  }
  #modal-card-container{
    /*height: calc(100vh - 60px) !important;*/
    overflow: visible !important;
    margin-top: 64px !important;
  }
  #modal-card-list{
    height: calc(100vh - 80px) !important;
    overflow: hidden !important;
    margin-top: 64px !important;
  }
  .mapView >div{
      width: auto !important;
      height: calc(100vh - 0px)!important;
    }
}
@media(max-width:992px){
.ant-popover.ant-popover-placement-top {
  top: 355px !important;
}
.ant-popover-arrow{
  bottom: 93px !important;
  /* top: 131px; */
  left: 122px !important;
  transform: rotate(224deg) !important;;
}
.scale-view-container{
  margin-bottom: 10% !important;
}
}
.details-list-view::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.details-list-view::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 #firebaseui_container{
   width:450px;
 }
/* Handle */
.details-list-view::-webkit-scrollbar-thumb {
  background: #333333 !important; 
  border-radius: 10px;
}

/* Handle on hover */
.details-list-view::-webkit-scrollbar-thumb:hover {
  background: #333333 !important; 
}
.details-list-view{
max-height: calc(100vh - 240px);
overflow-y: auto;
}
/* .hourly-image > html >  body > img {
  width: 100% !important;
} */
@media(max-width:767px){
  #firebaseui_container{
    width:100% !important;
  }
  #pit-detail-view{
    flex-wrap: wrap;
  }
  #wrap-content-view{
    justify-content: center;
    position: relative;
    right: 4px;
  }
  #wrap-content-image{
    width: 80% !important;
    height: unset !important;
  }
.value-based-images{
  width: 100% !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

#hourly-slider{
  width: 85% !important;
}

.hourly-image{
  width: 100% !important;
}

#hourly-slider-view {
  width: 100% !important;
}
}
html > body > img :{
  width: 100% !important;
}
.location-value-view{
  padding-left: 5px;
}
#degree-view::after {
  content: "\00b0"
}
.snow-button::selection{
  background: red !important;
}
.snow-button:hover, .snow-button:focus, .snow-button:active, .snow-button.active{
  background: lightgrey !important;
  color: #fff !important;
  border:1px solid lightgrey !important;
}
.hourly-container{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
#radio-button-view .ant-radio-inner::after{
  background-color:#34ebdf !important;
  top: 0px;
  left: 0px;
  width: 12px !important;
  height: 12px !important;
}
#radio-button-view .ant-radio-inner{
  background-color: #000;
  border-width: 2px;
  border-color: #d9d9d973 !important;
}
@media(max-width:767px){
  #location-content-view{
    right:21% !important;
    /* bottom: 13% !important; */
  }
}

@media(max-width:767px){
  /* #stack-content-view{
    right:19% !important;
  } */
  
}
@media(max-width:370px){
  #stack-content-view .slick-slider.slick-initialized {
    width: 140px !important;
  }
  #slider-text-view{
    font-size: 11px !important;
  }
  #slope-angle-view{
    width: 100% !important;
  }
}
.hourly-container .ant-slider-mark-text{
  color: #fff !important;
}
.hourly-container span.ant-slider-mark-text.ant-slider-mark-text-active{
  color: #fff !important;
}
#hourly-slider-view{
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #000000;
  width: 60%;
}

#hourly-slider .ant-slider-dot-active {
  border-color: #ffffff !important;
}

#hourly-slider .ant-slider-handle {
  background-color: #fff !important;
  border: solid 2px #ffffff !important;
}

#snow-slider-fullView .ant-slider-mark-text{
  color: #fff !important;
}
.snow-slider-fullView span.ant-slider-mark-text.ant-slider-mark-text-active{
  color: #fff !important;
}

#snow-slider-view{
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #000000;
  width: 60%;
}

#snow-slider .ant-slider-dot-active {
  border-color: #ffffff !important;
}

#snow-slider .ant-slider-handle {
  background-color: #fff !important;
  border: solid 2px #ffffff !important;
}

.mapboxgl-marker > p{
  color: aqua !important;
  position: absolute;
  top:0;
  left: 0;
  /* margin-top: -10%; */
}
.mapboxgl-marker svg{
  transform: translate(0px, -20px) !important;
}
.mapboxgl-marker{
  position: absolute;
  top:0;
  left: 0;
  will-change: transform;
}

#home-search .ant-input-search-enter-button + .ant-input-group-addon .ant-input-search-button, .ant-input-search-enter-button input + .ant-input-group-addon .ant-input-search-button {
  left: 44px;
}

#home-search .ant-input-group-wrapper {
  width: 90%;
}


/************************************/
/* #splash {
  background-color: #00FFFF;
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 10;
  transition-property: top;
  transition-duration: 300ms;
  transition-delay: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

@media (min-height: 840px) {
  #splash {
    transition-duration: 200ms;
    transition-delay: 200ms;
  }
}

#splash.animate {
  top: -100vh;
}

.splash_image{
  position: relative;
  display: inline-block;
  left: 50%;
  top: 30%;
  transform: translate(-50%);
} */
/************************************/
.snow-slider .ant-tooltip.ant-tooltip-placement-top{
  display: none;
}
@media(min-width: 685px) {
#preference-detail-value .ant-radio-wrapper{
  position: absolute;
  right: -28px;
}
#preference-detail-value .ant-list-item{
  padding-left: 0;
}
#preference-detail-value{
  width: 98%;
}
.preference-list-value {
  background-color: white;
  margin-left: 0%;
  margin-right: 0%
}
}
@media(max-width: 685px) {
  .preference-list-value {
  background-color: white;
  margin-left: 6%;
  margin-right: 3%
}
.Demo__search-bar-container{
  display: block !important;
}
}
#search-topo-icon{
  cursor: pointer;
}
.ant-checkbox-checked .ant-checkbox-inner{
  background-color: #000 !important;
  border-color: #000 !important;
}
@media(max-width:350px){
  #search-topo-icon{
    position: absolute !important; 
    left: 2% !important;
    top: 20px !important;
  }
}
@media(min-width:351px) and (max-width:390px){
  #search-topo-icon{
    position: absolute !important; 
    left: 2% !important;
    top: 20px !important;
  }
}
@media(min-width:391px) and (max-width:450px){
  #search-topo-icon{
    position: absolute !important; 
    left: 2% !important;
    top: 20px !important;
  }
}
@media(min-width:451px) and (max-width:550px){
  #search-topo-icon{
    position: absolute !important; 
    left: 2% !important;
    top: 20px !important;
  }
}


@media(min-width:551px) and (max-width:767px){
    #search-topo-icon{
    position: absolute !important; 
    left: 4% !important;
    top: 20px !important;
  }
}

@media(max-width:767px){
  #content-view-icon{
    width: 20% !important;
  }
  #content-view-text{
    width: 75% !important;
  }
}
@media(min-width:768px) and (max-width:1024px){
  #content-view-icon{
    width: 30% !important;
  }
  #content-view-text{
    width: 60% !important;
  }
    #search-topo-icon{
    position: absolute !important; 
    left: 4% !important;
    top: 20px !important;
  }
}
	